import React from 'react';
import PropTypes from 'prop-types';

import { getConfig } from '@edx/frontend-platform';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';

const CourseSidebar = ({
  courseNumber,
  courseImageUrl,
  effort,
  collections,
  availableLanguages,
  products,
  programs,
  intl,
}) => (
  <div className="course-sidebar">
    <div className="course-summary">

      <header className="course-profile">
        <div className="intro-inner-wrapper">
          <div className="table">
            <div className="media">
              <div className="hero">
                <img src={`${getConfig().LMS_BASE_URL}${courseImageUrl}`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </header>

      <ol className="important-dates">
        <li className="important-dates-item" key="course-number">
          <p className="important-dates-item-title">
            {intl.formatMessage({ id: 'courseAbout.courseNumber', defaultMessage: 'Course Number:' })}
          </p>
          <span className="important-dates-item-text course-number">{courseNumber}</span>
        </li>
        {availableLanguages.length > 0
            && (
            <li className="important-dates-item important-dates-item--languages" key="languages">
              <p className="important-dates-item-title">
                {intl.formatMessage({ id: 'courseAbout.availableLanguages', defaultMessage: 'Available Language(s):' })}
              </p>
              <div className="important-dates-item-links">
                {availableLanguages?.map(([lang, link], i) => {
                  const sep = i + 1 < availableLanguages.length ? ', ' : '';
                  return (<a href={link} key={lang}>{lang}{sep}</a>);
                })}
              </div>
            </li>
            )}
        {effort && (
        <li className="important-dates-item" key="course-start">
          <p className="important-dates-item-title">
            {intl.formatMessage({ id: 'courseAbout.effort', defaultMessage: 'Estimated Effort:' })}
          </p>
          <span>{effort}</span>
        </li>
        )}
        {collections && Object.entries(collections).map(([key, value]) => (
          <li className="important-dates-item" key={key}>
            <p className="important-dates-item-title">
              {key}:
            </p>
            <span>{value}</span>
          </li>
        ))}
        {products.length > 0 && (
          <li className="important-dates-item" key="course-products">
            <p className="important-dates-item-title">
              {intl.formatMessage({ id: 'courseAbout.products', defaultMessage: 'Product(s):' })}
            </p>
            <span>{products.join(', ')}</span>
          </li>
        )}
        {programs.length > 0 && (
          <li className="important-dates-item" key="course-programs">
            <p className="important-dates-item-title">
              {intl.formatMessage({ id: 'courseAbout.programs', defaultMessage: 'Program(s):' })}
            </p>
            <span>{programs.join(', ')}</span>
          </li>
        )}
      </ol>
    </div>
  </div>
);

CourseSidebar.propTypes = {
  courseNumber: PropTypes.string.isRequired,
  collections: PropTypes.shape({}).isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  courseImageUrl: PropTypes.string.isRequired,
  effort: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  programs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CourseSidebar);
