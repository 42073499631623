import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render a list of search results.
 */
const SearchResult = ({ infoMsg, paginationMsg, results }) => (
  <>
    <div id="discovery-form" role="search" aria-label="course" className="wrapper-search-context">
      <div id="discovery-message" className="search-status-label">
        {infoMsg}
        {paginationMsg && (
          <span className="search-status-label-info">
            {paginationMsg}
          </span>
        )}
      </div>
    </div>
    <div className="courses no-course-discovery" role="region" aria-label="List of Results">
      <div className="courses-list-holder">
        <ul className="courses-listing courses-list">
          {results}
        </ul>
      </div>
    </div>
  </>
);

export default SearchResult;

SearchResult.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})),
  infoMsg: PropTypes.string.isRequired,
  paginationMsg: PropTypes.string.isRequired,
};

SearchResult.defaultProps = {
  results: [],
};
